/* eslint-disable no-undef */
import { getVectorLayer, getVectorChart, getSeaDistance, getLayerCoordinatesValues } from '@/api/services.js';
import LineString from 'ol/geom/LineString';
import { i18n } from '@/plugins/i18n';
import { getLength } from 'ol/sphere';
import { log } from '@/constants.js';
import router from '@/router/index.js';
import * as olExtent from 'ol/extent';

// --------------------- STATE -----------------------------

export const state = {
  elevation: '',
  sea_distance: '',
  date: null,
  testMap: null,
  openRasterLayerTableChartPopup: false,
  openVectorLayerTableChartPopup: false,
  renderDialog: false,
  isTable: false,
  rasterTableHeaders: [{ text: i18n.t('ServiceCard.date'), value: 'time' }],
  header_alternativeTemplate: [{ header: 'heading', dataKey: 'heading' }, { header: 'value', dataKey: 'value' }],
  allWorkspacesData: { data: {}, metadata: {} },
  isRuntimeDomain: {},
  meteogramData: {},
  tableChartRangeDate: {},
  layersChartColors: {},
  rasterTableItems: [],
  rasterVectorChartLabels: [],
  rasterVectorChartDataSetBar: [],
  rasterVectorChartDataSetLine: [],
  allVectorFeatures: [],
  vectorTableHeaders: [],
  completeVectorChartItems: [],
  vectorChartItem: [],
  vectorFeatureProperties: [],
  vectorFeatureIDResp: []
};

// --------------------- MUTATIONS ----------------------------

export const mutations = {
  GET_ALL_WORKSPACES_DATA(state, allWorkspacesData) {
    Object.keys(allWorkspacesData.data).forEach(key => {
      state.allWorkspacesData.data[key] = allWorkspacesData.data[key];
      state.allWorkspacesData.metadata = allWorkspacesData.metadata;
    });
  },
  GET_ALL_METEOGRAMS_DATA(state, data) {
    state.meteogramData = data;
  },
  GET_UPDATED_RASTER_TABLE_CHART(state, updatedTable) {
    if (state.isTable) {
      state.rasterTableItems = [];

      const latitude = updatedTable.metadata.latitude;
      const longitude = updatedTable.metadata.longitude;

      const layers = Object.keys(updatedTable.data);

      updatedTable.data.time.forEach((time, index) => {
        const rasterTableChartLayers = {};
        rasterTableChartLayers.latitude = latitude;
        rasterTableChartLayers.longitude = longitude;

        layers.forEach(layer => {
          rasterTableChartLayers[layer] = typeof updatedTable.data[layer][index] === 'number' ? updatedTable.data[layer][index].toFixed(1) : updatedTable.data[layer][index];
        });
        state.rasterTableItems.push(rasterTableChartLayers);
      });
    } else {
      state.rasterVectorChartLabels = [];
      state.rasterVectorChartDataSetLine = [];
      state.rasterVectorChartDataSetBar = [];

      const layers = Object.keys(updatedTable.data);

      state.rasterVectorChartLabels = updatedTable.data.time;

      layers.forEach(layer => {
        if (layer !== 'time') {
          const layerColor = state.layersChartColors[updatedTable.service_id][layer];
          const randomColor = '#' + (Math.random().toString(16) + '000000').substring(2, 8);

          state.rasterVectorChartDataSetLine.push({
            label: `${i18n.t(`${layer}_serviceID:${updatedTable.service_id}.label`)}${i18n.t(`${layer}_serviceID:${updatedTable.service_id}.udm`)}`,
            type: 'line',
            borderColor: layerColor || randomColor,
            fill: false,
            data: updatedTable.data[layer].map(item => item ? item.toFixed(1) : item)
          });
          state.rasterVectorChartDataSetBar.push({
            label: `${i18n.t(`${layer}_serviceID:${updatedTable.service_id}.label`)}${i18n.t(`${layer}_serviceID:${updatedTable.service_id}.udm`)}`,
            type: 'bar',
            backgroundColor: layerColor || randomColor,
            fill: false,
            data: updatedTable.data[layer].map(item => item ? item.toFixed(1) : item)
          });
        }
      });
    }
  },
  GET_FILTERED_CHART_ITEMS(state, data) {
    state.vectorChartItem = state.completeVectorChartItems.filter(item => item.service_id === data.service_id);
    state.vectorChartItem[0].feature_id = data.feature_id;
  },
  SET_ELEVATION(state, data) {
    state.elevation = data;
  },
  SET_SEA_DISTANCE(state, data) {
    state.sea_distance = data;
  }
};

export const actions = {
// ------------------------ RASTER ---------------------------
  async getLayerCoordinatesDataAction({ state, commit }, data) {
    // eslint-disable-next-line no-unused-vars
    const [isRuntime, timeStep, timeStepType] = state.isRuntimeDomain[data.service_id] || [];
    const isV2 = ['HFS_ITA_4KM_1H', 'HFS_EUR_12KM_1H', 'HFS_WOR_25KM_1H'].includes(data.workspace) && isRuntime;

    const chunks = (arr, limit) => {
      const chunks = [];

      if (limit) {
        for (let i = 0;i < arr.length;i += limit) {
          chunks.push(arr.slice(i, i + limit));
        }

        return chunks;
      }

      return [arr];
    };

    let layer_limit_num;

    if (data.workspace.toUpperCase().startsWith('HRS') || data.workspace.toUpperCase().startsWith('HFS')) {
      if (data.workspace.toUpperCase().startsWith('HRS')) {
        layer_limit_num = 5;
      } else {
        layer_limit_num = 16;
      }
    }

    const chunksArray = [];
    const arrayOfChunks = chunks(data.layers, layer_limit_num);
    arrayOfChunks.forEach(e => {
      const obj = { ...data };
      obj.layers = e;
      chunksArray.push(obj);
    });

    try {
      const unifiedResp = { data: {}, metadata: {} };

      for await (const item of chunksArray) {
        const resp = await getLayerCoordinatesValues(item, isV2);
        unifiedResp.data = Object.assign(unifiedResp.data, resp.data.data);
        unifiedResp.metadata = resp.data.metadata;
      }

      await commit('GET_ALL_WORKSPACES_DATA', unifiedResp);
    } catch (err) {
      log.error(err);
      log.error(`WORKSPACE: ${data.workspace}:${data.layers} ERROR: ${err.response.data.MESSAGE}`);
    }
  },
  async getUpdatedRasterTableChartAction({ commit }, data) {
    try {
      await commit('GET_UPDATED_RASTER_TABLE_CHART', data);
    } catch (err) {
      log.error('getUpdatedRasterTableChartActionError', err);
    }
  },
  // ------------------------ VECTOR ---------------------------
  async getUpdatedVectorTableAction({ state, rootState, dispatch }, data) {
    state.allVectorFeatures = [];

    try {
      if (rootState.map.vectorBBOX.length > 0) {
        const resp = await getVectorLayer(data.params.LAYERS, rootState.map.vectorBBOX, data.params.TIME, data.params.USERNAME, data.params.SERVICE_NAME, router.currentRoute.params.id);

        resp.data.features.forEach(feature => {
          let line = null;

          if (feature.geometry.type === 'Point') {
            line = new LineString([rootState.map.distanceFromPoint, feature.geometry.coordinates]);
          }

          if (feature.geometry.type === 'MultiPolygon' || feature.geometry.type === 'Polygon') {
            line = new LineString([rootState.map.distanceFromPoint, olExtent.getCenter(feature.bbox)]);
          }

          const distance = line !== null ? getLength(line) : 0;

          const formatDistance = (length) => {
            if (rootState.map.distanceFromPoint.length > 0 && (feature.geometry.type === 'Point' || feature.geometry.type === 'MultiPolygon' || feature.geometry.type === 'Polygon')) {
              length = Math.round(length / 1000 * 100) / 100;
            } else {
              length = 'N/A';
            }

            return length;
          };

          let obj = {};
          feature.properties['Distanza dal punto (km)'] = formatDistance(distance);
          const vectorKey = Object.keys(feature.properties);

          const itemToMove = vectorKey.indexOf('Distanza dal punto (km)');
          const nextTo = (vectorKey.indexOf('ignore_id') !== -1) ? 2 : 1;
          const moveItem = vectorKey.splice(itemToMove, 1);
          vectorKey.splice(nextTo, 0, moveItem[0]);

          state.vectorTableHeaders = vectorKey.reduce((previous, current) => {
            if (previous.indexOf(current) < 0 && current !== 'geometry' && current !== 'ignore_id') {

              obj = {
                text: current.charAt(0) === '-' ? current.substring(1) : current,
                value: current, width: '1%'
              };

              if (obj.text === 'standard_property') {
                obj.text = data.params.LAYERS;
              }

              previous.push(obj);
            }

            return previous;
          }, []);

          obj = {};
          state.vectorTableHeaders = state.vectorTableHeaders.filter(e => !(e.value.charAt(0) === '_' && typeof feature.properties[e.value] === 'string'));
          state.vectorTableHeaders.forEach(item => {
            obj[item.value] = feature.properties[item.value] === null ? 'N/A' : feature.properties[item.value];
          });
          state.allVectorFeatures.push(obj);
        });
      }
    } catch (err) {
      log.error('vector load', err);
      dispatch(
        'storeToastMessage',
        {
          text: 'vector load',
          errObj: {
            errors: [
              { message: `LAYER: ${data.params.LAYERS} ERROR: ${err.response.data.MESSAGE}` }] },
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async getVectorChartSelectAction({ rootState, state }, data) {
    state.vectorFeatureProperties = [];
    const resp = await getVectorChart(data.params.LAYERS, rootState.map.vectorBBOX, data.params.TIME, data.params.USERNAME, data.params.SERVICE_NAME, data.FEATURE_ID, router.currentRoute.params.id);

    const keys = Object.keys(resp.data.features[0].properties);

    if (resp.data.features.length > 0) {
      resp.data.features.forEach(feature => {
        keys.forEach(key => {
          if (feature.properties[key] != null && key.substring(0, 1) === '_') {
            state.vectorFeatureProperties.push(key.substring(1));
          }
        });
      });

      state.vectorFeatureIDResp = resp.data.features.reverse();
    }
  },
  async getUpdatedVectorChartAction({ state }, [propertyObj, serviceID]) {
    state.rasterVectorChartLabels = [];
    state.rasterVectorChartDataSetLine = [];
    state.rasterVectorChartDataSetBar = [];

    const keys = Object.keys(propertyObj);

    state.rasterVectorChartLabels = state.vectorFeatureIDResp.map(feature => feature.properties.datetime_range);

    state.rasterVectorChartLabels.forEach((time, index) => {
      const properties = state.vectorFeatureIDResp[index].properties;

      keys.forEach(key => {
        const val = properties[key];

        if (typeof val === 'string') {
          const splitVals = val.split(',');
          propertyObj[key] = splitVals;
          splitVals.forEach(k => {
            const label = k.split('#')[0];

            if (!propertyObj[label]) {
              propertyObj[label] = [];
            }

            propertyObj[label].push(properties[`-${label}`]);
          });
        } else {
          propertyObj[key].push(val);
        }
      });
    });

    const createChartDataset = (label, type, color, data) => {
      const isBar = type === 'bar';

      return {
        label,
        type,
        [isBar ? 'backgroundColor' : 'borderColor']: color,
        fill: false,
        data,
        ...(isBar && { minBarLength: 2 })
      };
    };

    const getRandomColor = () => '#' + (Math.random().toString(16) + '000000').substring(2, 8);

    keys.forEach(key => {
      if (propertyObj[key].every(v => typeof v === 'string')) {
        propertyObj[key].forEach(e => {
          const [label, color] = e.split('#');
          const randomColor = color ? `#${color}` : getRandomColor();
          state.rasterVectorChartDataSetLine.push(createChartDataset(label, 'line', randomColor, propertyObj[label]));
          state.rasterVectorChartDataSetBar.push(createChartDataset(label, 'bar', randomColor, propertyObj[label]));
        });
      } else {
        const layerColor = state.layersChartColors[serviceID][key.substring(1)] || getRandomColor();
        const label = key.charAt(0) === '_'
          ? `${i18n.t(`${key.substring(1)}_serviceID:${serviceID}.label`)}${i18n.t(`${key.substring(1)}_serviceID:${serviceID}.udm`)}`
          : key.substring(1);

        state.rasterVectorChartDataSetLine.push(createChartDataset(label, 'line', layerColor, propertyObj[key]));
        state.rasterVectorChartDataSetBar.push(createChartDataset(label, 'bar', layerColor, propertyObj[key]));
      }
    });
  },
  // ------------------------ OTHER ---------------------------
  async getElevationAction({ commit }, data) {
    const [latitude, longitude] = data;

    const latlng = {
      lat: parseFloat(latitude),
      lng: parseFloat(longitude)
    };

    const elevator = new google.maps.ElevationService();
    await elevator
      .getElevationForLocations({ locations: [latlng] })
      .then(({ results }) => {
        if (results[0]) {
          commit('SET_ELEVATION', results[0].elevation);
        } else {
          commit('SET_ELEVATION', 'N/A');
        }
      })
      .catch((e) =>
        dispatch(
          'storeToastMessage',
          {
            text: 'getElevationAction',
            errObj: {
              errors: [{ message: 'Error while fetching Elevation.' + e }]
            },
            type: 'danger'
          },
          { root: true }
        )
      );
  },
  async getSeaDistanceAction({ commit, dispatch }, data) {
    try {
      const [latitude, longitude] = data;
      const resp = await getSeaDistance(latitude, longitude);
      commit('SET_SEA_DISTANCE', resp.data.sea_distance);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'getSeaDistance',
          errObj: {
            errors: [{ message: 'Error while fetching getSeaDistance.' }]
          },
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  // ------------------------ METEOGRAMS ---------------------------
  async getMeteogramLayersDataAction({ commit }, data) {
    const isV2 = ['HFS_ITA_4KM_1H', 'HFS_EUR_12KM_1H', 'HFS_WOR_25KM_1H'].includes(data.workspace);

    try {
      const resp = await getLayerCoordinatesValues(data, isV2);
      await commit('GET_ALL_METEOGRAMS_DATA', resp.data.data);
    } catch (err) {
      log.error(`METEOGRAM ERROR: ${err.response.data.MESSAGE}`);
    }
  }
};

export const getters = {};
