import axios from 'axios';
import { BASE_URL, VECTOR_BASE_URL, ALERTEXPOST_BASE_URL, REPORTS_BASE_URL } from '@/constants.js';
import { getJwtToken, getJwtIDToken } from '@/api/api.js';

// ------------------ AXIOS ---------------------

const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

const vectorApiClient = axios.create({
  baseURL: VECTOR_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

// const rasterApiClient = axios.create({
//   baseURL: RASTER_BASE_URL,
//   headers: {
//     "Content-Type": "application/json"
//   }
// });

const alertExPostApiClient = axios.create({
  baseURL: ALERTEXPOST_BASE_URL,
  // baseURL: "https://bvdk94t61g.execute-api.eu-central-1.amazonaws.com/dev/api",
  headers: {
    'Content-Type': 'application/json'
    // "x-api-key": "mm7t52GIsx2Zq5pAI1DM04g9RWA6QkGs8Kdov5ga"
  }
});

const reportsApiClient = axios.create({
  baseURL: REPORTS_BASE_URL,
  // baseURL: "https://allertameteo-api-ppr.radarmeteo.com/api/reports",
  headers: {
    'Content-Type': 'application/json'
    // "x-api-key": "JFTYwv8SFTd6Tu8V4kpy"
  }
});

const addAuthInterceptor = token => {
  apiClient.interceptors.request.use(config => {
    config.headers.Authorization = token ? `Bearer ${token}` : '';

    return config;
  });
};

const addVectorAuthInterceptor = token => {
  vectorApiClient.interceptors.request.use(config => {
    config.headers.Authorization = token ? `Bearer ${token}` : '';

    return config;
  });
};

const addAlertExPostAuthInterceptor = token => {
  alertExPostApiClient.interceptors.request.use(config => {
    config.headers.Authorization = token ? `Bearer ${token}` : '';

    return config;
  });
};

const reportsAuthInterceptor = token => {
  reportsApiClient.interceptors.request.use(config => {
    config.headers.Authorization = token ? `Bearer ${token}` : '';

    return config;
  });
};

export const getSeaDistance = async (latitude, longitude) => {
  const jwt = await getJwtIDToken();

  addVectorAuthInterceptor(jwt);

  return vectorApiClient.get(`/fe_sea_distance?LATITUDE=${latitude}&LONGITUDE=${longitude}`);
};

export const getWorkspaces = async () => {
  const jwt = await getJwtToken();

  addAuthInterceptor(jwt);

  return apiClient.get('/rest/workspaces');
};

export const getLayers = async () => {
  const jwt = await getJwtToken();

  addAuthInterceptor(jwt);

  return apiClient.get('/rest/layers');
};

export const getLayerPerName = async layerName => {
  const jwt = await getJwtToken();

  addAuthInterceptor(jwt);

  return apiClient.get(`/rest/layers/${layerName}`);
};

export const getWSLayers = async workspace => {
  const jwt = await getJwtToken();

  addAuthInterceptor(jwt);

  return apiClient.get(`/rest/workspaces/${workspace}/layers`);
};

export const getLegendValues = async (layerName, layerStyle) => {
  const jwt = await getJwtIDToken();

  addVectorAuthInterceptor(jwt);

  return vectorApiClient.get(`/fe_get_legend/geoserver/wms?service=WMS&version=1.1.0&request=GetLegendGraphic&layer=${layerName}&style=${layerStyle}&format=application/json`);
};

export const getWmtsDescribeDomains = async (layer, time, service_id, policy_id) => {
  const jwt = await getJwtIDToken();

  let url = `/fe_describe_domains/geoserver/gwc/service/wmts?request=DescribeDomains&Version=1.0.0&Layer=${layer}&TileMatrixSet=EPSG:4326&expandLimit=0&time=${time}`;

  if (service_id != undefined && policy_id != undefined) {
    url += `&bbox=${service_id}/${policy_id}`;
  }

  addVectorAuthInterceptor(jwt);

  return vectorApiClient.get(url);
};

export const getWmtsGetDomainValues = async (domain, layer, time, limit) => {
  const jwt = await getJwtIDToken();

  addVectorAuthInterceptor(jwt);

  return vectorApiClient.get(`/fe_describe_domains/geoserver/gwc/service/wmts?request=GetDomainValues&Version=1.0.0&Layer=${layer}&Domain=${domain}${limit ? `&Limit=${limit}` : ''}&FromValue=${time}`);
};

export const getCapabilities = async (ws, layer, type) => {
  const jwt = await getJwtIDToken();

  const service = type === 'VECTOR' ? 'wfs' : 'wms';

  addVectorAuthInterceptor(jwt);

  return vectorApiClient.get(`/fe_describe_domains/geoserver/${ws}/${layer}/ows?service=${service}&version=1.3.0&request=GetCapabilities`);
};

export const getVectorLayer = async (layer, extent, time, username, service_name, group, param_range) => {
  const jwt = await getJwtIDToken();

  let url = `/fe_ogc/geoserver/wfs?SERVICE=WFS&VERSION=1.0.0&REQUEST=GetFeature&TYPENAME=${layer}&OUTPUTFORMAT=application/json&SRSNAME=EPSG:3857&BBOX=${extent.join(',')},EPSG:3857&TIME=${time}&USERNAME=${username}&SERVICE_NAME=${service_name}&MAXFEATURES=2000&GROUP=${group}`;

  if (param_range != undefined) {
    url += `&PARAM_RANGE=${param_range}`;
  }

  addVectorAuthInterceptor(jwt);

  return vectorApiClient.get(url);
};

export const getVectorChart = async (layer, extent, time, username, service_name, feature_id, group) => {
  const jwt = await getJwtIDToken();

  addVectorAuthInterceptor(jwt);

  return vectorApiClient.get(`/fe_ogc/geoserver/wfs?SERVICE=WFS&VERSION=1.0.0&REQUEST=GetFeature&TYPENAME=${layer}&OUTPUTFORMAT=application/json&SRSNAME=EPSG:3857&BBOX=${extent.join(',')},EPSG:3857&TIME=${time}&USERNAME=${username}&SERVICE_NAME=${service_name}&FEATURE_ID=${feature_id}&MAXFEATURES=2000&GROUP=${group}`);
};

export const getLayerCoordinatesValues = async (data, isV2) => {
  const jwt = await getJwtIDToken();

  let urlParameters = `USERNAME=${data.username}&WORKSPACE=${data.workspace}&LAYERS=${data.layers}&LATITUDE=${data.latitude}&LONGITUDE=${data.longitude}&TIME=${data.time}&OUTPUT=${data.output}&TIMEZONE=${data.timezone}&SERVICE_NAME=${data.service_name}&GROUP=${data.group}`;

  const buildUrl = () => {
    if (isV2) {
      return `/fe_custom_v2?${urlParameters}`;
    }

    if (data.runtime) {
      urlParameters += `&RUNTIME=${data.runtime}`;
    }

    return `/fe_custom?${urlParameters}`;
  };

  addVectorAuthInterceptor(jwt);

  return vectorApiClient.get(buildUrl(isV2));
};

export const getAlertExPost = async url => {
  const jwt = await getJwtIDToken();

  addAlertExPostAuthInterceptor(jwt);

  return alertExPostApiClient.get(`/${url}`);
};

export const postAlertExPost = async (url, body) => {
  const jwt = await getJwtIDToken();

  addAlertExPostAuthInterceptor(jwt);

  return alertExPostApiClient.post(`/${url}`, body);
};

export const getReports = async (published, organizationID, type, year, page) => {
  const jwt = await getJwtIDToken();

  reportsAuthInterceptor(jwt);

  return reportsApiClient.get(`/reports?published=${published}&organizationId=${organizationID}&reportType=${type}&pageSize=100&page=${page}&sort_by=desc(reportDate)&from=${year}-01-01&to=${year}-12-31`);
};

export const getSingleReport = async (reportID) => {
  const jwt = await getJwtIDToken();

  reportsAuthInterceptor(jwt);

  return reportsApiClient.get(`/reports/${reportID}`);
};

export const getLatestReport = async (organizationID, type) => {
  const jwt = await getJwtIDToken();

  reportsAuthInterceptor(jwt);

  return reportsApiClient.get(`/reports?pageSize=1&published=true&organizationId=${organizationID}&reportType=${type}&sort_by=desc(reportDate)`);
};

export const getOldestReport = async (organizationID) => {
  const jwt = await getJwtIDToken();

  reportsAuthInterceptor(jwt);

  return reportsApiClient.get(`/reports?pageSize=1&published=true&organizationId=${organizationID}&sort_by=asc(reportDate)`);
};

export const getReportTemplate = async (templateID) => {
  const jwt = await getJwtIDToken();

  reportsAuthInterceptor(jwt);

  return reportsApiClient.get(`/reports/templates/${templateID}`);
};

export const getReportGEOJSON = async (reportID, areaID) => {
  const jwt = await getJwtIDToken();

  reportsAuthInterceptor(jwt);

  return reportsApiClient.get(`/kml/${reportID}/${areaID}`);
};

export const getReportPdf = async (organizationID, reportID, digest) => {
  const jwt = await getJwtIDToken();

  reportsAuthInterceptor(jwt);

  return reportsApiClient.get(`/report/${organizationID}/${reportID}/${digest}/download`, { responseType: 'blob' });
};

export const getLegendReport = async (organizationID, digestLegend) => {
  const jwt = await getJwtIDToken();

  reportsAuthInterceptor(jwt);

  return reportsApiClient.get(`/report/legend/${organizationID}/${digestLegend}`, { responseType: 'blob' });
};

export const getReportOrganization = async () => {
  const jwt = await getJwtIDToken();

  reportsAuthInterceptor(jwt);

  return reportsApiClient.get('/organizations?pageSize=500');
};
